'use strict';

(function () {
    
    document.addEventListener("DOMContentLoaded", function () {

            $(".form-howdidyouhear").change(function () {
                if ($(this).val() === "Other") {
                    $(".form-reason-label").css("display", "block");
                    $(".form-reason").css("display", "block");
                }
                else {
                    $(".form-reason-label").css("display", "none");
                    $(".form-reason").css("display", "none");
                }
            });
        const form = document.querySelectorAll('.form-section');

        if (form.length > 0) {
            form.forEach(instance => {

                
                var formPodcastTitle = instance.querySelector('.form-podcast-title');
                var podcastTitle = document.getElementById('InsightTitle');
                if (podcastTitle !== undefined && podcastTitle !== null && formPodcastTitle !== undefined && formPodcastTitle !== null) {
                    formPodcastTitle.value = podcastTitle.value;
                }

                var formCpdCreditsRequestEmail = instance.querySelector('.form-request-email');
                var cpdCreditsRequestEmail = document.getElementById('CpdCreditsRequestEmail');
                if (cpdCreditsRequestEmail !== undefined && cpdCreditsRequestEmail !== null && formCpdCreditsRequestEmail !== undefined && formCpdCreditsRequestEmail !== null) {
                    formCpdCreditsRequestEmail.value = cpdCreditsRequestEmail.value;
                }

                var formCpdCreditsRequestInsightUrl = instance.querySelector('.form-insight-url');
                var cpdCreditsRequestInsightUrl = document.getElementById('CpdCreditsRequestForm');
                if (cpdCreditsRequestInsightUrl !== undefined && cpdCreditsRequestInsightUrl !== null && formCpdCreditsRequestInsightUrl !== undefined && formCpdCreditsRequestInsightUrl !== null) {
                    formCpdCreditsRequestInsightUrl.value = cpdCreditsRequestInsightUrl.value;
                }

            });

            

            inputLabels = document.querySelectorAll(".input-cpd-label");
            if (inputLabels) {
                inputLabels.forEach(instance => {
                    instance.innerHTML = instance.innerHTML + "<span class='red-star'>*</span>";
                });
            }

            var noteLabel = document.querySelector(".note-cpd-label");
            if (noteLabel) {
                noteLabel.innerHTML = noteLabel.innerHTML + "<span class='red-star'>*</span>";
            }
        }



    });
})();
