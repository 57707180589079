'use strict';

(function () {
	const playButton = document.querySelector('.audio-play');

	function playAudio(e) {
		e.preventDefault();

		const container = this.parentNode;
		const audio = container.querySelector('audio');

		audio.play();
	}

	playButton && playButton.addEventListener('click', playAudio);
    if (window.location && window.location.pathname
        && window.location.pathname.toLowerCase().indexOf("/people/") >= 0) {
        var serviceareas = "";
        var layerData = document.getElementById("biodatalayer");
        if (layerData) {
            serviceareas = layerData.dataset.services;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "content": {
                    "service": serviceareas
                }
            });
        }
    }
})();