'use strict';

(function () {
    const accordions = document.querySelectorAll('.accordion')

    const accordionInit = accordion => {
        let items = accordion.querySelectorAll('.accordion-item')
        if (items.length > 0) {
            items.forEach(innerAccordion => {
                let title = innerAccordion.querySelector('.accordion-item-title')
                let content = innerAccordion.querySelector('.accordion-item-content')
                toggle(title, content)

                if (content.getAttribute('aria-hidden') != "true") {
                    setDisplayProperty(content, "inline");
                    content.style.height = content.scrollHeight + "px";
                    content.parentNode.classList.add('open')
                    content.setAttribute('aria-hidden', false);
                    content.setAttribute('aria-expanded', true);
                    
                }
                else {
                    setDisplayProperty(content, "none");
                }

            })
        }
    }

    const toggle = (title, content) => {
        title.addEventListener('click', () => {
            if (content.getAttribute('aria-hidden') == "true") {
                setDisplayProperty(content, "inline");
                content.style.height = content.scrollHeight + "px";
                content.parentNode.classList.add('open')
                content.setAttribute('aria-hidden', false);
                content.setAttribute('aria-expanded', true);
            } else {
                content.style.height = "0px";
                content.parentNode.classList.remove('open')
                content.setAttribute('aria-hidden', true);
                content.setAttribute('aria-expanded', false);
                setDisplayProperty(content, "none");
            }
        })


    }

    function setDisplayProperty(content, displayValue) {
        const keyboardfocusableElements = content.querySelectorAll(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        if (keyboardfocusableElements.length > 0) {
            keyboardfocusableElements.forEach(focusableElement => {
                focusableElement.style.display = displayValue;
            })
        }
    }


    if (accordions.length > 0 && !window.sc.isExperienceEditor) {
        accordions.forEach(accordion => {
            accordionInit(accordion)
        })
    }
})();