'use strict';

(function() {
	document.addEventListener('DOMContentLoaded', function() {
		const cookie = document.querySelectorAll('.cookie');
		const dateToday = new Date().valueOf();
		if (cookie.length > 0) {
			cookie.forEach((instance) => {
                 //FE cookie handler
				if (window.localStorage.getItem('accepted_cookie') != 'true' || window.localStorage.getItem('accepted_expiration') < dateToday) {
					instance.classList.remove('d-none');
				}
				const button = instance.querySelector('.cookie-btn-accept');
				if (button){                    
					button.addEventListener('click', () => {
						var today = new Date();
						var cookieName = 'CookieAcceptance';
						var cookieValue = today.toUTCString();
						var cookieExpire = 60 * 60 * 24 * 365;
						var cookiePath = '/';
						var cookieVal =
							cookieName + '=' + cookieValue + ';max-age=' + cookieExpire + ';path=' + cookiePath;

						document.cookie = cookieVal;
                        button.closest('.cookie').classList.add('d-none');
                        
                        //FE cookie handler
						window.localStorage.setItem('accepted_cookie', true);
						let date = new Date();
						date = date.setFullYear(date.getFullYear() + 1).valueOf();
						window.localStorage.setItem('accepted_expiration', date);
                    });                    
                }
			});
		}
	});
})();
