'use strict';

(function () {
    const summary = document.querySelectorAll('.summary');

    function init(container) {
        const sections = container.querySelectorAll('.rich-text');
        const readMoreContent = container.querySelector('.read-more--content');
        if (readMoreContent) {

            if (sections.length >= 3) {
                sections.forEach((section, index) => {
                    if (index >= 2) {
                        setDisplayProperty(section, "none");
                        readMoreContent.appendChild(section);
                    }
                });
            } else {
                readMoreContent.parentNode.remove();
            }
        }
    }

    function setDisplayProperty(content, displayValue) {
        const keyboardfocusableElements = content.querySelectorAll(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        if (keyboardfocusableElements.length > 0) {
            keyboardfocusableElements.forEach(focusableElement => {
                focusableElement.style.display = displayValue;
            })
        }
    }

    if (summary.length > 0 && !window.sc.isExperienceEditor) {
        summary.forEach(instance => {
            const readMore = instance.querySelector('.read-more');

            readMore && init(instance);
        });
    }
})();