'use strict';

(function () {
    let initMasonry = (elem) => {
        let searchPageTab = document.querySelector('#coveo8efcdcdd');
        if (elem) {
            let gutterSize = 40;

            if (searchPageTab) {
                gutterSize = 25;
            }

            let msnry = new Masonry(elem, {
                // options
                itemSelector: '.CoveoResult',
                columnWidth: '.CoveoResult',
                gutter: gutterSize,
                percentPosition: true
            });
        }
    };

    let removeEmptyFacets = () => {
        if (!window.sc.isExperienceEditor) {
            document.querySelectorAll('.collapsibleFacetWrapper').forEach((item) => {
                if (
                    item.querySelectorAll('.coveo-facet-value').length < 2 &&
                    !item.querySelector('.CoveoFacetSlider') &&
                    !item.querySelector('.CoveoFacetRange')
                ) {
                    item.classList.add('d-none');
                } else {
                    item.classList.remove('d-none');
                }
            });
        }
    };

    let animate = () => {
        let visitedUrls = localStorage.getItem('visited_url');
        visitedUrls = visitedUrls ? visitedUrls.split(', ') : [];
        const thisUrl = window.location.href.replace(window.location.hash, '');

        if (!visitedUrls.includes(thisUrl)) {
            let animated = document.querySelectorAll('.CoveoResult');
            if (animated.length > 0) {
                animated.forEach((item) => {
                    item.classList.add('animate');
                    item.classList.add('animate-start');
                });
                let delay = 200;
                let togglerInBottonDistance = 200;

                const animateStuff = () => {
                    animated.forEach((item, index) => {
                        let position = item.getBoundingClientRect().top + window.scrollY;

                        if (window.scrollY + window.innerHeight - togglerInBottonDistance > position) {
                            setTimeout(() => {
                                item.classList.add('animate-finish');
                                item.addEventListener('animationend', () => {
                                    item.classList.remove('animate');
                                    item.classList.remove('animate-finish');
                                });
                            }, delay * index);
                            item.classList.remove('animate-start');
                            animated = document.querySelectorAll('.animate-start');
                        }
                    });
                };
                animateStuff();
                window.addEventListener('scroll', animateStuff);

                visitedUrls.push(thisUrl);
                localStorage.setItem('visited_url', visitedUrls.join(', '));
            }
        }
    };

    function runSharedListing() {
        const socialShare = document.querySelectorAll('.coveo-main-section .social-share');

        function closeAll(e) {
            const event = e || window.event;
            const element = event.srcElement ? event.srcElement : event.target;

            let clickInsideMenu = false;

            const openedSocialShareContainer = document.querySelectorAll('.coveo-main-section .social-share-list');
            openedSocialShareContainer.forEach((i) => {
                if (element === i) clickInsideMenu = true;
                return;
            });

            const openedSocialShare = document.querySelectorAll('.coveo-main-section .social-share-list *');
            openedSocialShare.forEach((i) => {
                if (element === i) clickInsideMenu = true;
                return;
            });

            if (clickInsideMenu) return;

            function isDropdown(container) {
                return element === container ? true : container.contains(element);
            }

            socialShare.forEach((instance) => {
                if (!isDropdown(instance)) {
                    document
                        .querySelectorAll(
                            '.coveo-main-section  .social-share-toggler.social-share-close[aria-expanded=true]'
                        )
                        .forEach((openedCloseButton) => openedCloseButton.click());
                }
            });
        }

        function toggle(container) {
            const buttons = container.querySelectorAll('.coveo-main-section .social-share-toggler');
            const popup = container.querySelector('.coveo-main-section .social-share-list');

            if (buttons[0].getAttribute('aria-expanded') === 'true') {
                // close
                buttons.forEach((button) => {
                    button.setAttribute('aria-expanded', 'false');
                });

                popup.classList.remove('open');
                if (document.querySelectorAll('.coveo-main-section .social-share-list.open').length < 1) {
                    document.removeEventListener('click', closeAll);
                    document.removeEventListener('touchstart', closeAll);
                }
            } else {
                // open
                buttons.forEach((button) => {
                    button.setAttribute('aria-expanded', 'true');
                });

                popup.classList.add('open');

                document.removeEventListener('click', closeAll);
                document.removeEventListener('touchstart', closeAll);

                document.addEventListener('click', closeAll);
                document.addEventListener('touchstart', closeAll);
            }
        }

        if (socialShare.length > 0) {
            socialShare.forEach((instance) => {
                const toggler = (e) => {
                    e.stopPropagation();
                    toggle(instance);
                };
                const buttons = instance.querySelectorAll('.coveo-main-section  .social-share-toggler');
                buttons.length > 0 &&
                    buttons.forEach((button) => {
                        button.removeEventListener('click', toggler);
                        button.addEventListener('click', toggler);
                    });
            });
        }
    }

    let scrollFlag = false;
    let pageAnchor = () => {
        if (document.querySelector('#coveoC606340E') && window.location.href.indexOf('insights') != -1) {
            if (window.location.href.indexOf('f:') != -1 && !scrollFlag) {
                window.scroll({
                    top: document.querySelector('#coveoC606340E').getBoundingClientRect().top + window.scrollY
                    //behavior: 'smooth',
                });
                scrollFlag = !scrollFlag;
            }
        }
    };

    let scrollFixFlag = false;
    if (!scrollFixFlag) {
        document.addEventListener('afterInitialization', function () {
            let masonryContainer = document.querySelector('.CoveoResultList .coveo-result-list-container');
            if (masonryContainer) {
                scrollFixFlag = true;
                document.addEventListener('scroll', () => {
                    initMasonry(document.querySelector('.CoveoResultList .coveo-result-list-container'));
                });
            }
        });
    }

    var fixPeopleCardHeight = function () {
        var peopleCards = document.querySelectorAll('.CoveoResultList:not([data-layout=list]) .bio-card');
        if (peopleCards.length > 0) {
            var maximumHeight = 0;
            peopleCards.forEach((i) => {
                if (i.offsetHeight > maximumHeight) maximumHeight = i.offsetHeight;
            });
            peopleCards.forEach((i) => {
                i.style.height = maximumHeight + 7 + 'px';
            });
        }
    };

    let getTabs = () => {
        if (window.media.isCoveoCollapse()) {
            let allTabs = document.querySelectorAll('.CoveoTab');
            let coveoTabSection = document.querySelector('.coveo-tab-section');

            if (allTabs.length > 0 && coveoTabSection) {
                coveoTabSection.classList.remove('coveo-tab-section');
                coveoTabSection.classList.add('nav-container');

                coveoTabSection.innerHTML = `
				<section class="anchor-navigation blg-white">
					<div class="wrapper">
						<div class="anchor-navigation-list">
							<div class="mobile-only menu-choice"></div>
						</div>
					</div>
				</section>`;

                let newList = document.querySelector('.anchor-navigation-list');
                let newListContainer = document.querySelector('.anchor-navigation');

                allTabs.forEach((i) => {
                    i.classList.remove('CoveoTab');
                    i.classList.remove('coveo-accessible-button');
                    newList.appendChild(i);

                    i.addEventListener('click', function () {
                        window.scroll({
                            top: 0,
                            behavior: 'smooth'
                        });
                        newList.classList.toggle('open');
                    });
                });

                const originalAnchorY = newListContainer.getBoundingClientRect().top + window.scrollY;
                window.addEventListener('scroll', () => {
                    if (window.scrollY > originalAnchorY) {
                        if (!newListContainer.classList.contains('fixed')) {
                            newListContainer.classList.add('fixed');
                        }
                    } else {
                        if (newListContainer.classList.contains('fixed')) {
                            newListContainer.classList.remove('fixed');
                        }
                    }
                });
                let menuChoice = document.querySelector('.menu-choice');
                menuChoice.addEventListener('click', () => {
                    newList.classList.toggle('open');
                });
            }
        }
    };

    let checkDisplayMode = () => {
        if (document.querySelector('#coveo8efcdcdd')) {
            if (window.location.href.indexOf('coveo8efcdcdd') == -1 && window.location.href.indexOf('card') != -1) {
                window.location.href = window.location.href.replace('card', 'list');
            }
            let sliders = document.querySelectorAll('.CoveoFacetSlider');
            let dateSort = document.querySelector('#coveo8fa26aa3');
            if (window.location.href.indexOf('coveo8efcdcdd') > -1) {
                // inside people tab
                if (sliders.length > 0) {
                    sliders.forEach((s) => s.classList.add('d-none'));
                }
                if (dateSort) {
                    dateSort.parentElement.classList.add('d-none');
                }
            } else {
                if (sliders.length > 0) {
                    sliders.forEach((s) => s.classList.remove('d-none'));
                }
                if (dateSort) {
                    dateSort.parentElement.classList.remove('d-none');
                }
            }
        }
    };

    let filterButtonPositioning = () => {
        let insightsContainer = document.querySelector('.insight-search-interface');
        let searchPageContainer = document.querySelector('.search-page-interface');
        if (insightsContainer && window.media.isCoveoCollapse()) {
            let filterButton = insightsContainer.querySelector('.coveo-dropdown-header-wrapper');
            let headerListingContainer = insightsContainer.querySelector('.coveo-header');
            let resultList = insightsContainer.querySelector('.CoveoResultList');
            if (filterButton && headerListingContainer && resultList) {
                filterButton.style.paddingTop = '20px';
                headerListingContainer.appendChild(filterButton);
                resultList.style.marginTop = '-30px';
            }
        }
        if (searchPageContainer && window.media.isCoveoCollapse()) {
            let filterButton = searchPageContainer.querySelector('.coveo-dropdown-header-wrapper');
            let resultsColumn = searchPageContainer.querySelector('.coveo-results-column');
            if (filterButton && resultsColumn && resultsColumn.querySelector('.coveo-results-header')) {
                resultsColumn.insertBefore(
                    filterButton,
                    resultsColumn.querySelector('.coveo-results-header').nextSibling
                );
                filterButton.style.paddingTop = '30px';
            }
        }
        let filterToggler = document.querySelector('a.coveo-dropdown-header');
        if (filterToggler) {
            var promise = window.getSCLabel('{1AF72F36-60BC-42D8-8C6B-A72A9FE379DF}');
            promise.then((result) => {
                let str = result.replace(/^"(.*)"$/, '$1');
                filterToggler.innerText = str.length > 0 ? str : 'Filters';
            });
            filterToggler.addEventListener('click', () => {
                let facets = document.querySelector('.coveo-facet-column');
                if (facets) {
                    facets.scrollIntoView(true);
                    window.setTimeout(() => facets.scrollIntoView(true), 100);
                }
            });
        }
    };

    let removeForcedEmptyLinks = () => {
        let nonLinkedTitles = document.querySelectorAll('span.CoveoResultLink.coveo-card-title');
        if (nonLinkedTitles.length > 0) {
            nonLinkedTitles.forEach((i) => {
                var clone = i.cloneNode(true);
                i.parentNode.replaceChild(clone, i);
            });
        }
    };

    let addThisWorkaround = () => {
        if (addthis) {
            allShare = document.querySelectorAll('.addthis_inline_share_toolbox');
            if (allShare.length > 0) {
                allShare.forEach((shareAddress) => {
                    let newAddress = shareAddress.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(
                        '.CoveoResultLink'
                    );
                    if (newAddress) {
                        shareAddress.dataset.url = newAddress.href;
                    }
                    shareAddress.dataset.url = shareAddress.dataset.url.replace(/\[/g, '%5B').replace(/\]/g, '%5D');
                });
                addthis.layers.refresh();
            }
            addthis.layers.refresh();
        } else {
            setTimeout(() => {
                if (addthis && addthis.layers.length > 0) {
                    addthis.layers.refresh();
                }
            }, 300);
        }
    };

    document.addEventListener('afterInitialization', () => {
        getTabs();
        //if (addthis && addthis.layers.length > 0) {
        //    try {
        //        addthis.layers.refresh();
        //    } catch (e) {
        //        //
        //    }
        //}
    });

    document.addEventListener('newResultsDisplayed', async () => {
        checkDisplayMode();
        runSharedListing();
        animate();
        initMasonry(document.querySelector('.CoveoResultList .coveo-result-list-container'));
        fixPeopleCardHeight();
        setTimeout(removeEmptyFacets, 300);
        pageAnchor();
        filterButtonPositioning();
        removeForcedEmptyLinks();
        //addThisWorkaround();
    });
})();
