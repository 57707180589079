'use strict';

(function () {
  let animated = document.querySelectorAll('.animate-start')
  const thisUrl = window.location.href;

  let visitedUrls = localStorage.getItem('visited_url')
  visitedUrls = visitedUrls ? visitedUrls.split(', ') : [];


  const isFirstVisit = () => {
    return !visitedUrls.includes(thisUrl)
  }

  if (animated.length > 0) {
    if (isFirstVisit()) {
      visitedUrls.push(thisUrl)
      localStorage.setItem('visited_url', visitedUrls.join(', '))

      let delay = 200;
      let togglerInBottonDistance = 200;

      const animateStuff = () => {
        animated.forEach((item, index) => {
          let position = item.getBoundingClientRect().top + window.scrollY

          if (window.scrollY + window.innerHeight - togglerInBottonDistance > position) {
            setTimeout(() => {
              item.classList.add('animate-finish')
            }, delay * index)
            item.classList.remove('animate-start')
            animated = document.querySelectorAll('.animate-start')
          }
        })
      }
      animateStuff()
      window.addEventListener('scroll', animateStuff)
    } else {
      animated.forEach(item => {
        item.classList.remove('animate')
        item.classList.remove('animate-start')
      })
    }
  }
})();