'use strict';

(function () {
    const tabs = document.querySelectorAll('.tab');
    const panels = document.querySelectorAll('.tab-panel');

    function open() {
        const tab = this;
        const panelId = tab.getAttribute('aria-controls');
        const panel = document.getElementById(panelId);

        tabs.forEach(tab => {
            tab.setAttribute('aria-selected', false);
        });

        if (panels.length > 0) {
            panels.forEach(panel => {
                panel.setAttribute('hidden', 'hidden');
            });
        }

        tab.setAttribute('aria-selected', true);
        panel.removeAttribute('hidden');
    }

    tabs.length > 0 && tabs.forEach(tab => {
        tab.addEventListener('click', open);
    });
})();