'use strict';

(function () {
    const bow = document.querySelectorAll('.bow');

    if (bow.length > 0 && !window.sc.isExperienceEditor) {
        bow.forEach(instance => {
            const button = instance.querySelector('.read-more--toggle');
            if (button) button.addEventListener('click', () => {
                let containers = instance.querySelectorAll('ul')

                if (button.getAttribute("aria-expanded") == "false") {
                    button.setAttribute('aria-expanded', true);
                    button.setAttribute('data-read', 'less');

                    containers.forEach(ul => {
                        ul.querySelectorAll('.bow-item').forEach(li => {
                            li.classList.remove('d-none')
                        })
                    })
                } else {
                    button.setAttribute('aria-expanded', false);

                    containers.forEach(ul => {
                        ul.querySelectorAll('.bow-item').forEach((li, index) => {
                            if (index > 3) li.classList.add('d-none')
                        })
                    })
                }
            });
        })
    }
})();