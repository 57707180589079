(function() {
	const heroVideo = document.querySelectorAll('.hero-video');

	if (heroVideo.length > 0) {
		heroVideo.forEach((hv) => {
			let video = hv.querySelector('.video');
			if (video) {
				let mobileVideo = video.dataset.mobileVideo;
				let deskVideo = video.dataset.deskVideo;

				let loadedVideo = document.createElement('source');

				if (window.media.isDesktop()) {
					if (deskVideo) {
						loadedVideo.src = deskVideo;
						video.appendChild(loadedVideo);
					}
				} else {
					if (mobileVideo) {
						loadedVideo.src = mobileVideo;
						video.appendChild(loadedVideo);
					}
				}
			}
			let delayLoad = hv.querySelector('.delayed');
			if (delayLoad) {
				let imgSources = delayLoad.querySelectorAll('source');
				if (imgSources) {
					window.setTimeout(() => {
						imgSources.forEach((i) => {
							i.srcset = i.dataset.srcset;
						});
					}, 4000);
				}
			}
		});
	}
})();
