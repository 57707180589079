'use strict';

(function() {
	const readMore = document.querySelectorAll('.read-more');

	function toggle() {
		const button = this;
		const container = button.parentNode;
		const content = container.querySelector('.read-more--content');
		const contentHeight = content.scrollHeight;
		const isCollapsed = container.getAttribute('data-collapsed') === 'true';
		const innerItems = document.querySelectorAll('.read-more--list ul li ul li');
		const innerOlItems = document.querySelectorAll('.read-more--list ul li ol li');

		if (isCollapsed) {
			setDisplayProperty(content, "inline");
			content.style.height = content.scrollHeight + 'px';
			content.setAttribute('aria-hidden', false);
			button.setAttribute('aria-expanded', true);
			container.setAttribute('data-collapsed', 'false');
		} else {
			setDisplayProperty(content, "none");
			content.style.height = '0';
			content.setAttribute('aria-hidden', true);
			button.setAttribute('aria-expanded', false);
			container.setAttribute('data-collapsed', 'true');
		}
		if (innerItems) {
			innerItems.forEach((i) => {
				i.removeAttribute('aria-hidden');
				setDisplayProperty(i, "inline");
			});
		}
		if (innerOlItems) {
			innerOlItems.forEach((i) => {
				i.removeAttribute('aria-hidden');
				setDisplayProperty(i, "inline");
			});
		}
	}

	function showMore() {
		const button = this;
		const container = button.parentNode;
		const pageSize = container.dataset.pageSize;
		const list = container.querySelector('.read-more--list');
		const listItems = list.querySelectorAll('li[aria-hidden=true]');
		let remainingItems = 0;

		if (button.getAttribute('aria-expanded') !== 'true') {
			listItems.forEach((item, index) => {
				if (index < pageSize) {
					item.removeAttribute('aria-hidden');
					setDisplayProperty(item, "inline");
				}
			});

			remainingItems = list.querySelectorAll('li[aria-hidden=true]').length;

			if (remainingItems === 0) {
				button.setAttribute('aria-expanded', true);
			}
		} else {
			init(container);
			button.setAttribute('aria-expanded', false);
		}

		list.style.height = list.querySelector('ul').clientHeight;
	}

	function init(container) {
		const button = container.querySelector('.read-more--toggle');
		const pageSize = container.dataset.pageSize;
		const list = container.querySelector('.read-more--list');
		const listItems = list.querySelectorAll('li');
		const innerItems = document.querySelectorAll('.read-more--list ul li ul li');
		const innerOlItems = document.querySelectorAll('.read-more--list ul li ol li');

		if (listItems.length <= pageSize) {
			button.style.display = 'none';
		} else {
			listItems.forEach((item, index) => {
				if (index >= pageSize) {
					item.setAttribute('aria-hidden', true);
					setDisplayProperty(item, "none");
				}
			});

			list.style.height = list.querySelector('ul').clientHeight;
		}
		if (innerItems) {
			innerItems.forEach((i) => {
				i.removeAttribute('aria-hidden');
				setDisplayProperty(i, "inline");
			});
		}
		if (innerOlItems) {
			innerOlItems.forEach((i) => {
				i.removeAttribute('aria-hidden');
				setDisplayProperty(i, "inline");
			});
		}
	}

	readMore &&
		readMore.forEach((instance) => {
			const button = instance.querySelector('.read-more--toggle');
			const type = button.parentNode.dataset.type;

			if (type && type === 'list') {
				init(instance);
				button.addEventListener('click', showMore);
			} else {
				button.addEventListener('click', toggle);
			}
		});

	function setDisplayProperty(content, displayValue) {
		const keyboardfocusableElements = content.querySelectorAll(
			'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
		);
		if (keyboardfocusableElements.length > 0) {
			keyboardfocusableElements.forEach(focusableElement => {
				focusableElement.style.display = displayValue;
			})
		}
	}
})();
