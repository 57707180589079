'use strict';

(function () {
    document.addEventListener("DOMContentLoaded", function () {
        const form = document.querySelectorAll('.form-section');

        if (form.length > 0) {
            form.forEach(instance => {

                var formRefId = instance.querySelector('.form-reference-id');
                var jobRefId = document.getElementById('JobRefID');
                if (jobRefId !== undefined && jobRefId !== null && formRefId !== undefined && formRefId !== null) {
                    formRefId.value = jobRefId.value;
                }
                //Assign value to recruiter email form field from the hidden field
                var formRecruiterEmail = instance.querySelector('.form-recruiter-email');
                var jobRecruiterEmail = document.getElementById('JobRecruiterEmail');
                if (jobRecruiterEmail !== undefined && jobRecruiterEmail !== null && formRecruiterEmail !== undefined && formRecruiterEmail !== null) {
                    formRecruiterEmail.value = jobRecruiterEmail.value;
                }
                var formPositionType = instance.querySelector('.form-position-type');
                var jobPositionType = document.getElementById('PositionType');
                if (jobPositionType !== undefined && jobPositionType !== null && formPositionType !== undefined && formPositionType !== null) {
                    formPositionType.value = jobPositionType.value;
                }

                var formOpportunityTitle = instance.querySelector('.form-opportunity-title');
                var jobOpportunityTitle = document.getElementById('JobTitle');
                if (jobOpportunityTitle !== undefined && jobOpportunityTitle !== null && formOpportunityTitle !== undefined && formOpportunityTitle !== null) {
                    formOpportunityTitle.value = jobOpportunityTitle.value;
                }

                var formOffice = instance.querySelector('.form-office');
                var jobOffice = document.getElementById('JobResponsibleOffice');
                if (jobOffice !== undefined && jobOffice !== null && formOffice !== undefined && formOffice !== null) {
                    formOffice.value = jobOffice.value;
                }

                var submit = instance.querySelector('input[type=submit]');
                if (submit) {
                    var newButton = document.createElement('button');
                    newButton.type = submit.type;
                    newButton.innerText = submit.value;
                    newButton.classList.add('cta-button');
                    newButton.name = submit.name;
                    submit.parentNode.replaceChild(newButton, submit);
                }

                var fileUploadSection = instance.querySelector('.file-upload');
                if (fileUploadSection) {
                    fileUploadSection.addEventListener('change', function (e) {
                        if (e.target.className.lastIndexOf('coverletter') > -1) {
                            relocateMobileFileValidation("coverletter");
                        }
                        else if (e.target.className.lastIndexOf('resume') > -1) {
                            relocateMobileFileValidation("resume");
                        }
                    });
                }
            });

            var inputLabels = document.querySelectorAll(".file-upload-header-row .input-label");
            var newParentHtml = "";
            var labelParentElement;
            if (inputLabels) {
                inputLabels.forEach(instance => {
                    labelParentElement = instance.parentElement;
                    newParentHtml = newParentHtml + "<div class='input-wrapper' >" + instance.outerHTML + "</div>";
                });
                labelParentElement.innerHTML = newParentHtml;
            }

            inputLabels = document.querySelectorAll(".input-label");
            if (inputLabels) {
                inputLabels.forEach(instance => {
                    instance.innerHTML = instance.innerHTML + "<span class='red-star'>*</span>";
                });
                addMobileLabelsAndMove("coverletter");
                addMobileLabelsAndMove("resume");
            }

            var noteLabel = document.querySelector(".note-label");
            if (noteLabel) {
                noteLabel.innerHTML = noteLabel.innerHTML + "<span class='red-star'>*</span>";
            }

            var errors = document.querySelectorAll(".field-validation-error");
            if (errors) {
                errors.forEach(instance => {
                    var input = instance.previousElementSibling;
                    input.classList.add("red-border");
                });
            }
        }

        function addMobileLabelsAndMove(labelName) {
            var labelSection = document.querySelector("." + labelName + "-form-section");
            var moveToLabelSection = document.querySelector(".file-upload-header-row .label-" + labelName);
            if (labelSection) {
                var elChild = document.createElement('div');
                elChild.classList.add('mobile-label-' + labelName);
                elChild.innerHTML = document.querySelector(".label-" + labelName).innerHTML;
                moveToLabelSection.parentNode.insertBefore(elChild, moveToLabelSection);
                moveToLabelSection.parentNode.insertBefore(labelSection, moveToLabelSection.nextSibling);
            }
        }

        function relocateMobileFileValidation(fileType) {
            console.log('begin');
            var fileUploadSections = document.querySelectorAll('.file-upload');
            if (fileUploadSections) {
                fileUploadSections.forEach(instance => {
                    var validationError = instance.querySelector('.field-validation-error');
                    var hasFileType = instance.querySelector('.' + fileType + '-field');
                    var fileTypeSection = document.querySelector('.' + fileType + '-form-section');
                    if (validationError && hasFileType && fileTypeSection) {
                        console.log(fileType);
                        validationError.classList.add('mobile-file-validator-error');
                        fileTypeSection.append(validationError);
                    }
                });
            }
            console.log("end");
        }

        function truncate(n, len) {
            var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
            var filename = n.replace('.' + ext, '');
            if (filename.length <= len) {
                return n;
            }
            filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
            return filename + '.' + ext;
        };

        var fileUploadPopulate = function (input, fileType) {
            var section = document.querySelector("." + fileType + "-form-section");
            var noFileExists = document.querySelector('.no-file-selected-template');

            if (section && noFileExists) {
                noFileExists = noFileExists.innerHTML
                section.innerHTML = document.querySelector('.' + fileType + '-upload-button-template').innerHTML;
                section.innerHTML += "<div class='" + fileType + "-section'>" + noFileExists + "</div>";
            }

            input.addEventListener('change', function (e) {
                var fileName = '';
                fileName = e.target.value.split('\\').pop();

                if (fileName) {
                    fileName = truncate(fileName, 7);
                    var noFileSelected = document.querySelector("." + fileType + "-section .no-file-selected");
                    if (noFileSelected) {
                        noFileSelected.style.display = "none";
                    }
                    var removeButtonTemplate = document.querySelector('.' + fileType + '-remove-file-template');
                    var fileNameTemplate = document.querySelector('.' + fileType + '-file-name-template');
                    var fileSection = document.querySelector("." + fileType + "-section");
                    fileSection.innerHTML = fileNameTemplate.innerHTML;
                    fileSection.innerHTML = fileSection.innerHTML.replace("{{fileName}}", fileName);
                    fileSection.innerHTML += removeButtonTemplate.innerHTML;

                    // Disable upload button
                    var uploadButton = document.querySelector("." + fileType + "-file-upload-button");
                    uploadButton.setAttribute("disabled", true);
                    uploadButton.classList.add("disabled-button");
                }
            });
        };

        var coverletterInput = document.querySelector('.coverletter');
        if (coverletterInput) {
            fileUploadPopulate(coverletterInput, 'coverletter');
        }


        var resumeInput = document.querySelector('.resume');
        if (resumeInput) {
            fileUploadPopulate(resumeInput, 'resume');
        }



    });
})();

function removeFile(fileType) {
    document.querySelector("." + fileType).value = "";
    var fileSection = document.querySelector("." + fileType + "-section");
    if (fileSection) {
        fileSection.innerHTML = document.querySelector('.no-file-selected-template').innerHTML;
    }
    var uploadButton = document.querySelector("." + fileType + "-file-upload-button");
    uploadButton.removeAttribute("disabled");
    uploadButton.classList.remove("disabled-button");
}

function selectFileClicked(fileType) {
    var button = document.querySelector("." + fileType + "-file-upload-button");
    if (!button.getAttribute("disabled")) {
        document.querySelector("." + fileType).click();
    }
}