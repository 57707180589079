'use strict';

(function () {
    const bioCards = document.querySelectorAll('.bio-card');
    const cardsContent = document.querySelectorAll('.bio-card-content');

    function toggle(card) {
        card.classList.toggle('flipped');
    }

    bioCards &&
        bioCards.forEach((card) => {
            const buttonMore = card.querySelector('.view-more');
            const buttonLess = card.querySelector('.view-less');

            buttonMore.addEventListener('click', function (e) {
                if (!card.classList.contains('flipped')) {
                    toggle(card);
                }
            });

            buttonLess.addEventListener('click', function () {
                if (card.classList.contains('flipped')) {
                    toggle(card);
                }
            });
        });

    if (cardsContent.length > 0) {
        cardsContent.forEach((i) => {
            i.style.paddingBottom = i.querySelector('.bio-card-buttons').offsetHeight + 'px';
        });
    }

    document.addEventListener('newResultsDisplayed', () => {
        const bioCards = document.querySelectorAll('.bio-card');

        function toggle(card) {
            card.classList.toggle('flipped');
        }

        bioCards &&
            bioCards.forEach((card) => {
                const buttonMore = card.querySelector('.view-more');
                const buttonLess = card.querySelector('.view-less');

                buttonMore.addEventListener('click', function () {
                    if (!card.classList.contains('flipped')) {
                        toggle(card);
                    }
                });

                buttonLess.addEventListener('click', function () {
                    if (card.classList.contains('flipped')) {
                        toggle(card);
                    }
                });
            });
    });
})();
