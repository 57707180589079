'use strict';

(function() {
	function setSearchboxPeopleLabel() {
		var peopleSearchInterface = document.querySelector('.people-search-page-interface');
		if (peopleSearchInterface) {
			var peopleSearchboxLabel = peopleSearchInterface.querySelector('.coveo-facet-column.facet-row');
			if (peopleSearchboxLabel) {
				let label = peopleSearchboxLabel.dataset.findByLabel;
				if (document.querySelector('.pre-label')) {
					document.querySelector('.pre-label').innerText = label;
				}
			}
		}
	}

	let moveSuggestions = () => {
		let magicBox = document.querySelector('.search-suggestions .CoveoSearchbox');
		let omniBox = document.querySelector('.coveo-custom-people-omnibox');

		if (magicBox && omniBox) {
			magicBox.appendChild(omniBox.parentNode);
		}
	};

	let lastNameFacet = null;
	let fillLastNameFacet = () => {
		let bioInitial = document.querySelector(
			'.listing-search-interface.people-search-page-interface [data-id=biolastnameinitial]'
		);
		if (bioInitial) {
			lastNameFacet = bioInitial.parentElement;
		}
	};

	function hideResults() {
		var peopleContainer = document.querySelector('.listing-search-interface.people-search-page-interface');

		if (peopleContainer) {
			if (lastNameFacet) {
				lastNameFacet.classList.add('CoveoHoriztonalFacet');
				lastNameFacet.querySelector('.title-toggler') &&
					lastNameFacet.querySelector('.title-toggler').classList.add('d-none');

				lastNameFacet.querySelector('.close') && lastNameFacet.querySelector('.close').classList.add('d-none');
				lastNameFacet.querySelector('.CoveoHierarchicalFacet') &&
					lastNameFacet.querySelector('.CoveoHierarchicalFacet').classList.remove('d-none');
				lastNameFacet.querySelector('.CoveoHierarchicalFacet') &&
					lastNameFacet.querySelector('.CoveoHierarchicalFacet').classList.remove('CollapsibleFacet');
			}

			var resultsColumn = peopleContainer.querySelector('.coveo-results-column');
			var coveoQuerySummary = peopleContainer.querySelector('.CoveoQuerySummary');
			var facetRow = peopleContainer.querySelector('.facet-row');
			var coveoResultsHeader = peopleContainer.querySelector('.coveo-results-header');
			var coveoHeader = peopleContainer.querySelector('.coveo-header');
			if (coveoHeader) {
				coveoHeader.style.visibility = 'hidden';
			}

			coveoQuerySummary && coveoQuerySummary.classList.add('d-none');
			resultsColumn && resultsColumn.classList.add('d-none');
			coveoResultsHeader && coveoResultsHeader.classList.add('d-none');
			if (facetRow) {
				facetRow.classList.add('empty-search');
				facetRow.classList.remove('display-search');
				if (window.media.isCoveoCollapse()) {
					let emptySearchEvent = new Event('emptySearch');
					document.dispatchEvent(emptySearchEvent);
				}
			}

			var viewAllButton = peopleContainer.querySelector('.people-search-button-view-all');
			if (viewAllButton) {
				viewAllButton.href = window.location + '&q=%20';
			}
		}
	}

	function showResults() {
		var peopleContainer = document.querySelector('.listing-search-interface.people-search-page-interface');
		if (peopleContainer) {
			if (lastNameFacet && !window.media.isCoveoCollapse()) {
				lastNameFacet.classList.remove('CoveoHoriztonalFacet');
				lastNameFacet.querySelector('.title-toggler') &&
					lastNameFacet.querySelector('.title-toggler').classList.remove('d-none');

				lastNameFacet.querySelector('.close') &&
					lastNameFacet.querySelector('.close').classList.remove('d-none');
				lastNameFacet.querySelector('.CoveoHierarchicalFacet') &&
					lastNameFacet.querySelector('.CoveoHierarchicalFacet').classList.add('d-none');
				lastNameFacet.querySelector('.CoveoHierarchicalFacet') &&
					lastNameFacet.querySelector('.CoveoHierarchicalFacet').classList.add('CollapsibleFacet');
			}

			var resultsColumn = peopleContainer.querySelector('.coveo-results-column');
			var coveoQuerySummary = peopleContainer.querySelector('.CoveoQuerySummary');
			var facetRow = peopleContainer.querySelector('.facet-row');
			var coveoResultsHeader = peopleContainer.querySelector('.coveo-results-header');
			var coveoHeader = peopleContainer.querySelector('.coveo-header');
			if (coveoHeader) {
				coveoHeader.style.visibility = 'visible';
			}
			coveoQuerySummary && coveoQuerySummary.classList.remove('d-none');
			resultsColumn && resultsColumn.classList.remove('d-none');
			coveoResultsHeader && coveoResultsHeader.classList.remove('d-none');

			if (facetRow) {
				facetRow.classList.remove('empty-search');
				facetRow.classList.add('display-search');
				if (window.media.isCoveoCollapse()) {
					var newSearchEvent = new Event('newSearch');
					document.dispatchEvent(newSearchEvent);
					document.querySelectorAll('.empty-search').forEach((i) => i.classList.remove('empty-search'));
				}
			}
		}
	}

	let mobileView = () => {
		let mobilePeopleContainer = document.querySelector('.listing-search-interface.coveo-small-interface');

		if (mobilePeopleContainer) {
			let filterButton = mobilePeopleContainer.querySelector('.coveo-dropdown-header-wrapper');
			let mainSection = mobilePeopleContainer.querySelector('.coveo-main-section');
			let hiddenColumn = mobilePeopleContainer.querySelector('.coveo-facet-column');
			let searchBar = mobilePeopleContainer.querySelector('.horizontal-section-bar');
			let buttons = mobilePeopleContainer.querySelector('.div-people-search-buttons');

			if (!mobilePeopleContainer.querySelector('.mobile-container')) {
				let divContainer = document.createElement('div');
				divContainer.classList.add('mobile-container');

				let barBG = document.createElement('div');
				barBG.classList.add('search-bar-container');
				barBG.classList.add('facet-row');
				if (searchBar) {
					barBG.appendChild(searchBar);
				}
				barBG.appendChild(buttons);
				divContainer.appendChild(barBG);
				divContainer.appendChild(filterButton);

				mainSection.insertBefore(divContainer, hiddenColumn);
			}
		}
	};

	let resizeFix = () => {
		//Another coveo forced behavior. Attached to resize event
		let mobilePeopleContainer = document.querySelector(
			'.listing-search-interface.people-search-page-interface.coveo-small-interface'
		);
		if (
			mobilePeopleContainer &&
			mobilePeopleContainer.querySelector('.search-bar-container .coveo-dropdown-header-wrapper')
		) {
			let newFilterButton = mobilePeopleContainer.querySelector(
				'.search-bar-container .coveo-dropdown-header-wrapper'
			);
			let divContainer = mobilePeopleContainer.querySelector('.mobile-container');
			divContainer.appendChild(newFilterButton);
		}
	};

	let handleVisibility = () => {
		if (window.media.isCoveoCollapse()) {
			mobileView();
		}
		if (window.location.hash.includes('q=') || window.location.hash.includes('f:')) {
			showResults();
		} else {
			hideResults();
		}
		
	};

	if (!window.sc.isExperienceEditor) {
		document.addEventListener('newResultsDisplayed', handleVisibility);

		//Coveo would force the button to the same dinamically genereted container as search bar, breaking the styling.
		window.addEventListener('resize', () => {
			setTimeout(resizeFix, 300);
		});
	}

	document.addEventListener('afterInitialization', function() {
		var peopleSearchInterface = document.querySelector('.people-search-page-interface');
		if (peopleSearchInterface) {
			var searchBarButton = document.querySelector('.people-search-page-interface .CoveoSearchButton');
			if (searchBarButton) {
				var peopleSearchButton = document.querySelector('.people-search-button-search');
				if (peopleSearchButton) {
					peopleSearchButton.addEventListener('click', function() {
						var query = document.querySelector(
							".people-search-page-interface .magic-box-input span[data-id='Word']"
						);
						var buttonHref = peopleSearchButton.getAttribute('data-link');
						if (
							buttonHref !== undefined &&
							buttonHref !== null &&
							buttonHref !== '' &&
							query !== undefined &&
							query !== null
						) {
							var queryValue = query.getAttribute('data-value');
							if (queryValue !== undefined && queryValue !== null && queryValue !== '') {
								buttonHref += '#q=' + queryValue + '&sort=relevancy';
								window.location = buttonHref;
							}
						}
					});
				}
			}
			if (!window.sc.isExperienceEditor) {
				handleVisibility();
			}
		}
		setSearchboxPeopleLabel();
		moveSuggestions();
		fillLastNameFacet();
		

		let clearSearchButton = document.querySelector('.people-search-page-interface .magic-box-clear');
		if (clearSearchButton) {
			clearSearchButton.addEventListener('click', () => {
				console.log('blurring');
				document.activeElement.blur();
				window.setTimeout(() => {
					document.activeElement.blur();
				}, 100);
			});
		}
	});

	document.addEventListener('clearBreadcrumb', () => {
		//Coveo's breadcrumb hiding event has some delay that would cause misplacement on the footer
		let breadcrumb = document.querySelector('.CoveoBreadcrumb');
		if (breadcrumb) {
			breadcrumb.style.display = 'none';
		}
	});
})();
