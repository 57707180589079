'use strict';

(function() {
	const menu = document.querySelector('.main-nav');
	const tools = document.querySelector('.tools.menu');
	const classContentVisible = 'visible';
	const classLinkOpened = 'open';

	const isInitialMenuStateBlue = menu.classList.contains('blg-blue-header');

	const _getSiblings = (elem) => {
		const siblings = [];
		let sibling = elem.parentNode.firstChild;
		while (sibling) {
			if (sibling.nodeType === 1 && sibling !== elem) {
				siblings.push(sibling);
			}
			sibling = sibling.nextSibling;
		}
		return siblings;
	};

	const _closeOpenedMenus = () => {
		menu.firstLevelButtons.forEach((button) => {
			if (button.getAttribute('aria-expanded')) {
				button.setAttribute('aria-expanded', false);
				button.classList.remove(classLinkOpened);
				_getSiblings(button).forEach((container) => {
					container.classList.remove(classContentVisible);
					container.querySelectorAll('.tab-item-button').forEach((item) => {
						item.setAttribute('tabindex', -1);
					});
				});
			}
		});

		menu.querySelectorAll('.cta-button').forEach((button) => {
			button.setAttribute('tabindex', -1);
		});

		if (tools) {
			tools.querySelectorAll('a').forEach((link) => {
				link.setAttribute('tabindex', -1);
			});
		}
	};

	const _closeOpenedTabs = () => {
		menu.tabButtons.forEach((button) => {
			if (button.getAttribute('aria-expanded')) {
				button.setAttribute('aria-expanded', false);
				button.classList.remove(classLinkOpened);
				_getSiblings(button).forEach((container) => {
					container.classList.remove(classContentVisible);
					container.querySelectorAll('.column-items-container > li > a').forEach((item) => {
						item.setAttribute('tabindex', -1);
					});
				});
			}
		});
		menu.columnButtons.forEach((subButton) => {
			subButton.setAttribute('tabindex', -1);
			subButton.parentNode.querySelectorAll('.column-items-container > li > a').forEach((item) => {
				item.setAttribute('tabindex', -1);
			});
		});
	};

	const _listenOustideMenuClicks = (e) => {
		let clickInsideMenu = false;
		const fullMenu = document.querySelectorAll('.menu *');
		fullMenu.forEach((i) => {
			if (e.target == i) {
				clickInsideMenu = true;
				return;
			}
		});
		if (!clickInsideMenu) closeMegamenu();
	};

	const _removeEmptyMenuNodes = (link) => {
		const container = link.parentNode.querySelector('.container');
		if (container) {
			const isEmpty = container.querySelectorAll('.wrapper > ul > li').length < 1;
			if (isEmpty) {
				link.parentNode.removeChild(container);
			} else {
				link.setAttribute('aria-expanded', false);
				link.setAttribute('aria-haspopup', true);
			}
		}
	};

	const closeMegamenu = () => {
		_closeOpenedMenus();
		_closeOpenedTabs();
		document.removeEventListener('click', _listenOustideMenuClicks);
		//_focusOrder()
	};

	const positionColumns = () => {
		const tabContent = document.querySelectorAll('.tab-item-content .column-items-container');
		if (tabContent) {
			const fr = '1fr ';
			Array.from(tabContent).forEach((item) => {
				const childAmount = item.childElementCount;
				const cols = childAmount < 9 ? 2 : 3;
				const times = Math.ceil(childAmount / cols);
				item.style.gridTemplateRows = fr.repeat(times).toString();
			});
		}
	};

	const toggleMobileMenu = () => {
		document.querySelector('.main-nav').classList.toggle('menu-open');
		document.querySelector('html').classList.toggle('site-menu-open');
		document.querySelector('.menu-toggler').classList.toggle('open');
		closeMegamenu();
	};

	const toggleMenu = (event) => {
		let link = event.target;
		let menuContainer = link.parentNode.querySelector('.container');
		if (!menuContainer) {
			closeMegamenu();
			return;
		}
		document.addEventListener('click', _listenOustideMenuClicks);
		if (link.getAttribute('aria-expanded') == 'false') {
			_closeOpenedMenus();
			link.setAttribute('aria-expanded', true);
			link.classList.add(classLinkOpened);
			menuContainer.classList.add(classContentVisible);
			if (link.parentNode.querySelector('.tab-item-button')) {
				link.parentNode.querySelectorAll('.tab-item-button').forEach((subButton) => {
					subButton.setAttribute('tabindex', 2);
				});
				openMenuTab(undefined, link.parentNode.querySelector('.tab-item-button'));
				link.parentNode.querySelector('.tab-item-button').focus();
			}
			if (link.parentNode.querySelector('.column-item-button')) {
				link.parentNode.querySelectorAll('.column-item-button').forEach((subButton) => {
					subButton.setAttribute('tabindex', 2);
					subButton.parentNode.querySelectorAll('.column-items-container > li > a').forEach((item) => {
						item.setAttribute('tabindex', 3);
					});
					subButton.parentNode.querySelectorAll('.cta-button').forEach((item) => {
						item.setAttribute('tabindex', 3);
					});
				});
				link.parentNode.querySelector('.column-item-button').focus();
			} else if (link.parentNode.querySelector('.region-selector')) {
				link.parentNode.querySelectorAll('a').forEach((regionLink) => {
					regionLink.removeAttribute('tabindex');
				});
			}
		} else {
			_closeOpenedMenus();
			_closeOpenedTabs();
		}
	};

	const openMenuTab = (event, element) => {
		if (!event && document.querySelectorAll('.main-nav.menu-open').length > 0) return;
		const button = element || event.target;
		const tab = button.parentNode.querySelector('.tab-item-content');
		_closeOpenedTabs();
		if (button.getAttribute('aria-expanded') == 'false') {
			_closeOpenedTabs();
			button.setAttribute('aria-expanded', true);
			button.classList.add(classLinkOpened);
			tab.classList.add(classContentVisible);
			tab.querySelectorAll('.column-items-container > li > a').forEach((i) => i.setAttribute('tabindex', 3));
			tab.querySelectorAll('.cta-button').forEach((i) => i.setAttribute('tabindex', 3));
		}
	};

	const keyboardFunction = (e) => {
		//const TAB = 9
		const ESC = 27;
		const SPACE = 32;
		const ENTER = 13;
		const element = e.target;
		const keyCode = e.keyCode;

		switch (keyCode) {
			case ESC:
				if (document.querySelector('.menu-link.open')) document.querySelector('.menu-link.open').focus();
				closeMegamenu();
				break;
			case SPACE:
				e.preventDefault();
				element.click();
			case ENTER:
				if (element.className.includes('tab-item-button')) {
					element.parentNode.querySelector('.column-items-container > li > a').focus();
				}
			default:
				break;
		}
	};

	function goBackClick(e) {
		e.preventDefault();
		window.history.go(-1);
		return false;
	}

	const backButton = document.querySelector('.back-component');

	if (backButton) {
		backButton.addEventListener('click', goBackClick);
	}

	function focusAndOpenKeyboard(el, timeout) {
		//hacky iOS workaround - keyboard wouldn't pop up when opening the menu
		if (!timeout) {
			timeout = 100;
		}
		if (el) {
			// Align temp input element approximately where the input element is
			// so the cursor doesn't jump around
			var __tempEl__ = document.createElement('input');
			__tempEl__.style.position = 'absolute';
			__tempEl__.style.top = el.offsetTop + 7 + 'px';
			__tempEl__.style.left = el.offsetLeft + 'px';
			__tempEl__.style.height = 0;
			__tempEl__.style.opacity = 0;
			// Put this temp element as a child of the page <body> and focus on it
			document.body.appendChild(__tempEl__);
			__tempEl__.focus();

			// The keyboard is open. Now do a delayed focus on the target element
			setTimeout(function() {
				el.focus();
				el.click();
				// Remove the temp element
				document.body.removeChild(__tempEl__);
			}, timeout);
		}
	}

	if (menu && !window.sc.isExperienceEditor) {
		menu.mobileMenuButton = document.querySelector('.menu-toggler');
		menu.firstLevelButtons = document.querySelectorAll('.menu-link');
		menu.firstLevelClose = document.querySelectorAll('.close-button');
		menu.tabButtons = document.querySelectorAll('.tab-item-button');
		menu.columnButtons = document.querySelectorAll('.column-item-button');
		menu.closeTabButtons = document.querySelectorAll('.close-tab-button');

		menu.firstLevelButtons.forEach((link) => {
			_removeEmptyMenuNodes(link);
			link.addEventListener('click', toggleMenu);
		});

		menu.firstLevelClose.forEach((button) => {
			button.addEventListener('click', closeMegamenu);
		});

		menu.tabButtons.forEach((button) => {
			button.addEventListener('click', openMenuTab);
			button.setAttribute('aria-expanded', false);
			button.setAttribute('aria-haspopup', true);
		});

		menu.closeTabButtons.forEach((button) => {
			button.addEventListener('click', _closeOpenedTabs);
		});

		menu.mobileMenuButton.addEventListener('click', toggleMobileMenu);

		menu.addEventListener('keydown', keyboardFunction);
		positionColumns();
		closeMegamenu();

		/* Makes the search icon always visible in mobile */
		var becameMobile = false;
		let deskSearchContainer;
		let bgDiv = document.querySelector('.menu-search-bg');
		let bgDivLink = document.querySelector('a.show-search');

		let searchLabel = '';
		if (bgDivLink) {
			searchLabel = bgDivLink.innerText;
		}

		let toggleMobilePositioning = () => {
			let mobSearch = document.querySelector('.mobile-only.search');
			let toolsMenu = document.querySelector('.tools.menu');
			let menuToggler = document.querySelector('.menu-toggler.hamburger');
			if (mobSearch) {
				mobSearch.parentElement.removeChild(mobSearch);
			}

			if (!window.media.isDesktop() && !becameMobile) {
				becameMobile = true;

				if (toolsMenu) {
					toolsMenu.style.display = 'block !important;';
					deskSearchContainer = toolsMenu.querySelector('.desk-only');
					if (deskSearchContainer) {
						if (bgDivLink) {
							let svg = bgDivLink.firstElementChild;
							bgDivLink.innerText = '';
							if (svg) {
								bgDivLink.appendChild(svg);
							}
							menuToggler.parentElement.insertBefore(bgDivLink, menuToggler);
							menuToggler.parentElement.insertBefore(bgDiv, bgDivLink);
						}
					}
				}
			} else if (becameMobile && window.media.isDesktop()) {
				becameMobile = false;
				if (toolsMenu) {
					toolsMenu.style.display = '';

					if (deskSearchContainer) {
						if (bgDivLink) {
							bgDivLink.innerHTML += searchLabel;
							deskSearchContainer.appendChild(bgDiv);
							deskSearchContainer.appendChild(bgDivLink);
						}
					}
				}
				if (!isInitialMenuStateBlue) {
					menu.classList.remove('blg-blue-header');
				}
			}
		};

		window.addEventListener('resize', toggleMobilePositioning);

		let menuSearchBar = document.querySelector('#menu-search-bar');
		if (menuSearchBar) {
			document.addEventListener('DOMContentLoaded', function() {
				if (Coveo !== undefined && Coveo !== null) {
					//Coveo.SearchEndpoint.configureSampleEndpoint();
					var menuSearchBar = document.querySelector('#menu-search-bar');
					let url = menuSearchBar.querySelector('[data-url]');
					if (url) {
						url = url.getAttribute('data-url') || '/search';
					} else {
						url = '/search';
					}
					let closeLabel = menuSearchBar.getAttribute('data-close-btn-text');
					if (!closeLabel) {
						closeLabel = 'Close';
					}
					Coveo.initSearchbox(menuSearchBar, url).then((res, err) => {
						let searchContainer = document.querySelector('.main-nav');
						if (searchContainer) {
							//searchContainer.addEventListener("populateOmnibox", function (e) {
							//    if (initialOmnibox) {
							//        initialOmnibox(e);
							//    }
							//});

							let searchBg = searchContainer.querySelector('.menu-search-bg');
							let searchButton = searchContainer.querySelector('.show-search');
							if (searchButton && searchBg) {
								let magnifyingSvg = searchButton.innerHTML;

								let toggleSearch = () => {
									let logo = document.querySelector('a.logo');
									searchBg.classList.toggle('open');

									if (searchBg.classList.contains('open')) {
										document.body.style.overflow = 'hidden';
										searchButton.classList.add('open');
										if (logo) {
											logo.style.zIndex = '1000';
											logo.style.color = 'white';
										}
										searchButton.innerHTML = closeLabel;
									} else {
										document.body.style.overflow = '';
										searchButton.classList.remove('open');
										if (logo) {
											logo.style.zIndex = '';
											logo.style.color = '';
										}
										searchButton.innerHTML = magnifyingSvg;
									}
									if (document.activeElement) {
										document.activeElement.blur();
									}
								};

								let p = document.createElement('p');
								p.innerText = closeLabel;
								p.classList.add('close-search');

								let CoveoForSitecoreConfigureSearchHub = menuSearchBar.querySelector(
									'.CoveoForSitecoreConfigureSearchHub'
								);
								if (CoveoForSitecoreConfigureSearchHub) {
									CoveoForSitecoreConfigureSearchHub.appendChild(p);
								}

								searchButton.addEventListener('click', (e) => {
									closeMegamenu();
									e.preventDefault();
									toggleSearch();
									var menuSearchboxInput = searchContainer.querySelector('input');
									if (menuSearchboxInput) {
										if (searchBg.classList.contains('open')) {
											focusAndOpenKeyboard(menuSearchboxInput, 100);
										} else {
											document.activeElement.blur();
										}
									}

									let searchBar = document.querySelector('.magic-box-notEmpty');
									if (searchBar) {
										searchBar.querySelector('input').value = '';
										searchBar.classList.remove('magic-box-notEmpty');
									}
								});

								p.addEventListener('click', (e) => {
									e.preventDefault();
									toggleSearch();
								});
							}
						}
					});
					let mobileSearchBar = document.getElementById('mobile-search-bar');
					if (mobileSearchBar) {
						Coveo.initSearchbox(mobileSearchBar, url);
					}
					if (!window.media.isCoveoCollapse()) {
						let mobileSearch = document.querySelector('.mobile-only.search');
						if (mobileSearch) {
							mobileSearch.parentNode.removeChild(mobileSearch);
						}
					}
				}
				toggleMobilePositioning();
			});
		}
	}
})();

if (document.querySelector('.hero.blg-gray')) {
	document.querySelector('.main-nav').classList.add('blg-blue-header');
}
var prevScrollpos = window.pageYOffset;
// Scroll threshold where the navbar starts to hide
var NAVBAR_HIDE_SCROLL_THRESHOLD = 100;
var isBlgBlueHeaderClass = document.getElementById('main-navbar').className.includes('blg-blue-header');
window.onscroll = function() {
	if (window.innerWidth < 1025) {
		var currentScrollPos = window.pageYOffset;
		var navbarHeight = jQuery('#main-navbar-background').height();

		if (prevScrollpos > currentScrollPos || currentScrollPos < NAVBAR_HIDE_SCROLL_THRESHOLD) {
			setNavbarTop(0);
			setAnchorNavigationTop(navbarHeight - 1);
		} else if (!document.getElementById('main-navbar').className.includes('menu-open')) {
			setNavbarTop(-navbarHeight);
			setAnchorNavigationTop(0);
		} else {
			setNavbarTop(0);
			setAnchorNavigationTop(navbarHeight - 1);
		}

		prevScrollpos = currentScrollPos;

		if (currentScrollPos > NAVBAR_HIDE_SCROLL_THRESHOLD) {
			addBackground('white');
		} else {
			removeBackground(isBlgBlueHeaderClass);
		}

		if (!jQuery('.anchor-navigation').hasClass('fixed')) {
			setAnchorNavigationTop(0);
		}
	} else {
		setNavbarTop(0);
		setAnchorNavigationTop(0);
	}
};

function setNavbarTop(intPx) {
	document.getElementById('main-navbar').style.top = intPx + 'px';
}

function setAnchorNavigationTop(intPx) {
	jQuery('.anchor-navigation').css('top', intPx + 'px');
}

function addBackground(color) {
	jQuery('#main-navbar-background').css('background', color);
	jQuery('#main-navbar').addClass('blg-blue-header');
}

function removeBackground(isBlgBlueHeaderClass) {
	jQuery('#main-navbar-background').css('background', '');
	if (!isBlgBlueHeaderClass) {
		jQuery('#main-navbar').removeClass('blg-blue-header');
	}
}
//function AdjustNavigation(){
//	if (jQuery(location).attr('href').indexOf("/fr") > -1) {
		
//		if (window.innerWidth > 1024 && window.innerWidth <= 1284) {
//			jQuery(".show-search").css("font-size", "0");

//		}
//		else {
//			jQuery(".show-search").css("font-size", ".875rem");
//		}
//		if (window, innerWidth > 1024 && window, innerWidth <= 1260) {
//			jQuery(".main-nav .menu>li").css("padding", "0 8px");
//		}
//		if (window.innerWidth >= 1025 && window.innerWidth <= 1056) {
//			jQuery(".main-nav .menu>li").css("padding", "0 5px");
//		}
//	}
//	else if (jQuery(location).attr('href').indexOf("/en") > -1) {
//		if (window.innerWidth >= 1050 && window.innerWidth <= 1097) {
//			jQuery(".show-search").css("font-size", "0");
//			jQuery(".main-nav .menu>li").css("padding", "0 14px");
//		}
//    }
//}
//jQuery(window).resize(function () {
//	AdjustNavigation();
//})
//jQuery(window).ready(function () {
//	AdjustNavigation();
//})
