'use strict';

(function () {

    let forms = document.querySelectorAll('.custom-form')
  let idCounter = 0;

  function truncate(n, len) {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    var filename = n.replace('.' + ext, '');
    if (filename.length <= len) {
      return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
    return filename + '.' + ext;
  };

  forms.length > 0 && forms.forEach(form => {
    let labels = form.querySelectorAll('label')
    if (labels.length > 0) {
      labels.forEach(label => {
        let input = label.querySelector('input')
        if (input) {
          let innerText = label.innerText;
          input.id = input.type + idCounter++;
          let newLabel = document.createElement('label')
          newLabel.setAttribute('for', input.id)
          newLabel.innerText = innerText
          label.innerHTML = "";
          label.appendChild(input)
          label.appendChild(newLabel)

          let finalDiv = document.createElement('div')
          finalDiv.innerHTML = label.innerHTML
          label.parentNode.replaceChild(finalDiv, label)
        }
      })
    }
    let submit = form.querySelector('input[type=submit]')
    if (submit) {

      let newButton = document.createElement('button')
      newButton.type = submit.type
      newButton.innerText = submit.value
      newButton.classList.add('cta-button')
      newButton.name = submit.name

      submit.parentNode.replaceChild(newButton, submit)
    }

    const uploadFileInputs = form.querySelectorAll('input[type=file]')

    uploadFileInputs.length > 0 && uploadFileInputs.forEach(input => {
      let parentDiv = input.parentElement
      let label = parentDiv.querySelector('label')
      let labelText = label.innerText
      label.innerHTML = `<label style="display:block;margin-bottom: 10px;">${labelText}</label> <strong class="cta-button outline small">Select File <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 300"><path stroke-width="35" d="M184 55l-1 171 M194 25l-99 99M174 25l99 99 M282 271H86"></path></svg></strong>  <span class="empty" data-empty="No file selected"></span>`
      parentDiv.insertBefore(input, label)

      let labelVal = label.querySelector('span')
      let button = label.querySelector('strong')

      let freezeFile = (e) => {
        e.preventDefault()
      }

      let removeFile = (e) => {
        e.preventDefault()
        input.value = "";
        fileChanged()
        input.removeEventListener('click', freezeFile)
      }

      const fileChanged = () => {
        let filename = '';
        if (input.files.length > 0) {
          button.classList.add('disabled')
          filename = truncate(input.files[0].name.toLowerCase(), 7)
          labelVal.classList.remove('empty')
          labelVal.classList.add('full')
          filename += `<svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg"><g><path d="M.734 2.296L7.976 9.25.734 16.204 3.113 18.5h.003l9.635-9.25L3.115 0z"/><path d="M17.617 0L7.983 9.251l9.635 9.25 2.38-2.298-7.241-6.952 7.242-6.955L17.619 0z"/></g></svg>`
          input.addEventListener('click', freezeFile)
          labelVal.innerHTML = filename
          let closeIcon = labelVal.querySelector('svg')
          closeIcon.addEventListener('click', removeFile)
          closeIcon.tabIndex = 0
          closeIcon.addEventListener('keydown', (e) => {
            const keyCode = e.keyCode
            switch (keyCode) {
              case 32:
              case 13:
                removeFile(e)
                break;
              default:
                break;
            }

          })
        } else {
          labelVal.classList.add('empty')
          labelVal.classList.remove('full')
          button.classList.remove('disabled')
          labelVal.innerHTML = labelVal.dataset.empty
        }
      }
      fileChanged()
      input.addEventListener('change', () => fileChanged())
    });

    let dateInputs = form.querySelectorAll('input[type=date]')
    dateInputs.length > 0 && dateInputs.forEach(input => {
      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute('viewBox', '0 0 44 44')
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
      svg.innerHTML = `<path d="M27 34h-4v4h4zm0-7h-4v4h4zm0-7h-4v4h4zm6 7h-4v4h4zm-12-7h-4v4h4zm18 7h-4v4h4zm0-7h-4v4h4zm-6 0h-4v4h4zm-18 7h-4v4h4zm-6 7H5v4h4zm0-7H5v4h4zm0-7H5v4h4zM40 4h-4V1a1 1 0 0 0-2 0v3H10V1a1 1 0 0 0-2 0v3H4a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h36a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4zm2 36a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V16h40zm0-26H2V8a2 2 0 0 1 2-2h4v3a1 1 0 0 0 2 0V6h24v3a1 1 0 0 0 2 0V6h4a2 2 0 0 1 2 2zm-27 6h-4v4h4zm6 14h-4v4h4zm-6 0h-4v4h4zm6-7h-4v4h4z" data-name="Calendar 2@2x.png"/>`
      svg.classList.add('calendar')
      if (input.classList.contains('gray-bg')) {
        svg.style.borderRight = "3px solid #fff"
      }
      let dateContainer = input.parentNode;
      input.placeholder = dateContainer.querySelector('label').innerText
      if (window.matchMedia("(min-width: 1024px)").matches) {
        flatpickr(input, { disableMobile: true });
      }
      dateContainer.insertBefore(svg, input)
      input.classList.add('calendar-input')
      dateContainer.style.position = "relative"
    })

    let errorMsg = form.querySelectorAll('.field-validation-error')
    errorMsg.length > 0 && errorMsg.forEach(item => {
      if (item.innerText.length > 1) {
        item.parentNode.querySelectorAll('input').forEach(input => {
          input.classList.add('errored')
        })
      }
    })

    let requiredLabels = form.querySelectorAll('label')
    requiredLabels.length > 00 && requiredLabels.forEach(label => {
      if (label.innerText.indexOf('*') >= 0 && !label.querySelector('label')) {
        label.innerHTML = label.innerHTML.replace("*", "<span style='color:#d0021b;'>*</span>")
      }
    })

  })
})();