'use strict';

(function () {
  const flipCards = document.querySelectorAll('.flipcard');

  function toggle(card) {
    card.classList.toggle('flipped');
  }

  let tallest = 0;

  let resizeCard = () => {
    tallest = 0;
    let content = document.querySelectorAll('.info');
    if (content.length > 0) {
      content.forEach(card => tallest = card.offsetHeight > tallest ? card.offsetHeight : tallest)
      flipCards.forEach(card => card.style.height = (tallest + 50) + "px")
    }
  }

  if (flipCards.length > 0) {
    window.addEventListener('resize', resizeCard)
    resizeCard()
  }

  flipCards.length > 0 && flipCards.forEach(card => {
    const buttonMore = card.querySelector('.front');
    const buttonLess = card.querySelector('.close-icon');

    buttonMore.addEventListener('click', function () {
      toggle(card)
    });

    buttonLess.addEventListener('click', function () {
      toggle(card)
    });
  });
})();