'use strict';

const media = (function () {
    const breakpoints = {
        phone: 480,
        tablet: 768,
        coveoCollapse: 800,
        desktop: 1024,
        bigscreen: 1500
    }

    function _query(dir, bp) {
        return '(' + dir + '-width: ' + bp + 'px)';
    }

    function isPhone() {
        const mq = _query('max', breakpoints.phone);
        return window.matchMedia(mq).matches;
    }

    function isMobile() {
        const mq = _query('max', breakpoints.tablet);
        return window.matchMedia(mq).matches;
    }

    function isCoveoCollapse() {
        const mq = _query('max', breakpoints.coveoCollapse);
        return window.matchMedia(mq).matches;
    }

    function isTablet() {
        const mq = _query('min', breakpoints.tablet + 1) + ' and ' + _query('max', breakpoints.desktop);
        return window.matchMedia(mq).matches;
    }

    function isDesktop() {
        const mq = _query('min', breakpoints.desktop + 1);
        return window.matchMedia(mq).matches;
    }

    function isBigScreen() {
        const mq = _query('min', breakpoints.bigscreen + 1);
        return window.matchMedia(mq).matches;
    }

    return {
        isMobile = isMobile,
        isPhone = isPhone,
        isTablet = isTablet,
        isCoveoCollapse = isCoveoCollapse,
        isDesktop = isDesktop,
        isBigScreen = isBigScreen
    } 
 })();

 window.media = media;