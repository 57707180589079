'use strict';

(function () {
  document.addEventListener('deferredQuerySuccess', function () {
    changeDateFacetsInHorizontalBars()
    let facets = document.querySelectorAll('.CoveoFacet')
    let hierarquicalFacets = document.querySelectorAll('.CoveoHierarchicalFacet')
    let collapsibleFacetsToggler = document.querySelectorAll('[data-toggle-id]')
    let searchbar = document.querySelectorAll('.magic-box-input>input');

    if (facets.length > 0) {
      initFacets(facets)
    }
    if (hierarquicalFacets.length > 0) {
      initFacets(hierarquicalFacets)
    }
    if (collapsibleFacetsToggler.length > 0) {
      initCollapsibleFacets(collapsibleFacetsToggler)
    }

    if (window.media.isCoveoCollapse()) {
      mobileState()
    }

    if (searchbar.length > 0) {
      initSearchPageSearchBarSuggestion(searchbar);
    }

    })



    document.addEventListener('afterInitialization', () => {
        let searchbar = document.querySelectorAll('.magic-box-input>input');
        if (searchbar.length > 0) {
            initSearchPageSearchBarSuggestion(searchbar);
        }
    }); 


  let changeDateFacetsInHorizontalBars = () => {
    let bars = document.querySelectorAll(".coveo-facet-column.facet-row")
    bars.length > 0 && bars.forEach(bar => {
      let rangeFacets = bar.querySelectorAll('.CoveoFacetRange:not(.CollapsibleFacet)')
      let slideFacets = bar.querySelectorAll('.CoveoFacetSlider:not(.CollapsibleFacet)')
      let dateFacets = [...rangeFacets, ...slideFacets]

      dateFacets.length > 0 && dateFacets.forEach(item => {
        let toggler = document.createElement('div')
        toggler.classList.add('title-toggler')
        toggler.setAttribute('data-toggle-id', item.id)
        toggler.setAttribute('aria-expanded', false)
        toggler.innerHTML = item.dataset.title
        toggler.innerHTML += `<svg enable-background="new 0 0 10 6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg" class="coveo-facet-more-icon-svg"><g fill="currentColor"><path d="m5 5.932c-.222 0-.443-.084-.612-.253l-4.134-4.134c-.338-.338-.338-.886 0-1.224s.886-.338 1.224 0l3.522 3.521 3.523-3.521c.336-.338.886-.338 1.224 0s .337.886-.001 1.224l-4.135 4.134c-.168.169-.39.253-.611.253z"></path></g></svg>`

        item.classList.add('CollapsibleFacet')
        item.classList.add('CoveoFacet')
        item.classList.add('d-none')
        item.setAttribute('data-close-text', document.querySelector('[data-close-text]:not(.CoveoFacetRange):not(.CoveoFacetSlider)').dataset.closeText)
        item.setAttribute('data-clear-text', document.querySelector('[data-clear-text]:not(.CoveoFacetRange):not(.CoveoFacetSlider)').dataset.clearText)

        item.parentElement.classList.add('collapsibleFacetWrapper')
        item.parentElement.insertBefore(toggler, item)
      })
    })
  }

  let initFacets = facets => {
    facets.forEach(item => {
      let closeButton = item.querySelector('.coveo-facet-header-eraser')
      let settingsButton = item.querySelector('.coveo-facet-header-settings')
      if (closeButton) {
        let clearLabel = item.dataset.clearText ? item.dataset.clearText : "Clear All"
        closeButton.innerHTML = `<span class='clear'>${clearLabel}</span>`
      }
      if (settingsButton) {
        settingsButton.innerHTML = '<svg class="settings" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15"><path d="M9.6 7.5A2 2 0 0 0 9 6a2 2 0 0 0-1.6-.6A2 2 0 0 0 6 6a2 2 0 0 0-.6 1.5c0 .6.2 1.1.6 1.6.4.4 1 .6 1.5.6A2 2 0 0 0 9 9c.4-.5.6-1 .6-1.6zm4.3-.9v1.9l-.1.2h-.2l-1.5.3-.3.8a21.6 21.6 0 0 0 1 1.3l-.1.2-.9 1-.7.5h-.3l-1.1-1a5 5 0 0 1-.8.4l-.2 1.5c0 .2-.2.3-.3.3H6.5l-.2-.1v-.2L6 12.2l-.8-.3-1.2.9h-.2-.2l-1.4-1.5v-.2-.1l.5-.6.4-.6-.3-.8-1.5-.2-.2-.1V6.6v-.2l.1-.1L2.8 6c0-.3.2-.6.3-.8a18 18 0 0 0-1-1.4l.1-.2a7.7 7.7 0 0 1 1.6-1.5l.3.1 1.1 1 .8-.4.2-1.6.3-.2h2.1v.3L9 2.9c.3 0 .5.2.8.3l1.1-.9h.5l1.3 1.5.1.1v.2l-.5.6-.4.6.3.8 1.5.2.2.1v.2zm0 0"></path></svg>'
      }
    })
  }

  let initCollapsibleFacets = togglers => {
    if (window.media.isCoveoCollapse()) {
      togglers.forEach(toggler => {
        toggler.parentNode.removeChild(toggler)
      })
      let collapsibleFacets = document.querySelectorAll('.CollapsibleFacet')
      if (collapsibleFacets.length > 0) {
        collapsibleFacets.forEach(colFac => {
          colFac.classList.remove('CollapsibleFacet')
          colFac.classList.remove('d-none')
        })
      }

    } else {
      togglers.forEach(toggler => {
        let collapsibleFacet = document.querySelector("#" + toggler.dataset.toggleId)
        let icon = toggler.querySelector('svg')

        let toggleFacet = () => {
          collapsibleFacet.classList.toggle('d-none')
          if (collapsibleFacet.classList.contains('d-none')) {
            collapsibleFacet.setAttribute('aria-hidden', true);
            toggler.setAttribute('aria-expanded', false);
            icon.classList.remove('up')
          } else {
            closeAllOthers(collapsibleFacet)
            document.addEventListener('click', _listenOustideFacetClicks)
            collapsibleFacet.setAttribute('aria-hidden', false);
            toggler.setAttribute('aria-expanded', true);
            icon.classList.add('up')
          }
        }

        let _listenOustideFacetClicks = e => {

          if (e.target.closest('.collapsibleFacetWrapper') ||
            e.target.classList.toString().includes('coveo-facet-value') ||
            e.target.parentNode.classList.toString().includes('coveo-facet-value')) {
            return;
          } else {
            document.removeEventListener('click', _listenOustideFacetClicks)
            closeAllOthers(null)
          }
        }

        let closeAllOthers = keepOpened => {
          let allCollapsible = document.querySelectorAll('.CollapsibleFacet')
          if (allCollapsible.length > 0) {
            allCollapsible.forEach(item => {
              if (item != keepOpened) {
                let toggler = item.parentNode.querySelector('.title-toggler')
                let icon = toggler.querySelector('svg')

                item.classList.add('d-none')
                item.setAttribute('aria-hidden', true);
                icon.classList.remove('up')
                toggler.setAttribute('aria-expanded', false);
              }
            })
          }
        }

        if (collapsibleFacet) {
          if (!collapsibleFacet.querySelector('span.close')) {
            toggler.addEventListener('click', toggleFacet)
            let newButton = document.createElement('div')
            newButton.title = collapsibleFacet.dataset.closeText
            newButton.innerHTML = `<span class='close' title='${collapsibleFacet.dataset.closeText}'>${collapsibleFacet.dataset.closeText}</span>`
            newButton.querySelector('.close').addEventListener('click', toggleFacet)

            let settingsButton = collapsibleFacet.querySelector('.coveo-facet-header-settings')
            let settingsButtonContainer = collapsibleFacet.querySelector('.coveo-facet-header-settings-section')
            if (settingsButton) {
              settingsButton.parentNode.replaceChild(newButton, settingsButton)
            } else if (settingsButtonContainer) {
              settingsButtonContainer.appendChild(newButton)
            }

            let mutated = mutationList => {
              mutationList.forEach(m => {
                if (m.target.classList.contains('coveo-facet-empty')) {
                  toggler.parentNode.classList.add('d-none')
                } else {
                  toggler.parentNode.classList.remove('d-none')
                }
              })
            }

            let obsConfig = {
              attributes: true,
              attributeFilter: ['class']
            }

            let observer = new MutationObserver(mutated)
            observer.observe(collapsibleFacet, obsConfig)
          }
        }
      })
    }
  }

  let mobileState = () => {
    let smallFacets = document.querySelectorAll('.coveo-small-facets')

    smallFacets.length > 0 && smallFacets.forEach(smf => {
      let facetHeaders = smf.querySelectorAll('.coveo-facet-header')
      facetHeaders.length > 0 && facetHeaders.forEach(fh => {
        if (fh.getAttribute('changed') != "true") {

          fh.parentNode.classList.add('coveo-facet-collapsed')

          fh.querySelector('.coveo-facet-header-title-section').addEventListener('click', () => {
            fh.parentNode.classList.toggle('coveo-facet-collapsed')
          })

          let title = fh.querySelector('.coveo-facet-header-title')
          title.innerHTML = `<svg enable-background="new 0 0 16 16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="coveo-hierarchical-facet-expand-svg"><g fill="currentColor"><path d="m4.961 5.732c.192 0 .384.073.53.22l2.51 2.51 2.51-2.51c.293-.293.768-.293 1.061 0s .293.768 0 1.061l-3.041 3.04c-.141.14-.332.219-.53.219l0 0c-.199 0-.39-.079-.53-.22l-3.04-3.041c-.293-.293-.293-.768 0-1.061.146-.145.337-.218.53-.218z"></path></g></svg>` + title.innerHTML
          fh.setAttribute('changed', 'true')
        }
      })
    })

    let applyFilter = document.querySelector('.apply-filter')
    if (!applyFilter) {
      applyFilter = document.createElement('button')
      applyFilter.classList.add('apply-filter')
      var promise = window.getSCLabel("{512AA313-090F-41DA-AF62-1220A97595A6}");
      promise.then(result => {
        let str = result.replace(/^"(.*)"$/, '$1')
        applyFilter.innerText = str.length > 0 ? str : "Close Filter"
      })

      let applyContainer = document.createElement('div')
      applyContainer.classList.add('apply-filter-container')

      applyContainer.appendChild(applyFilter)

      let drawer = document.querySelector('.coveo-small-interface .coveo-facet-column')
      if (drawer) {
        drawer.prepend(applyContainer)
        applyFilter.addEventListener('click', () => {
          document.querySelector('.coveo-dropdown-background').click()
          document.querySelector('.coveo-dropdown-background').click() //for some reason Coveo will respond only to double-clicks 50% of the time
        })
      }
    }
  }

  let initSearchPageSearchBarSuggestion = (searchbar) => {
      searchbar.forEach(instance => {

          var coveoOmnibox = document.querySelector('.CoveoOmnibox');
          let searchButton = coveoOmnibox.parentNode.querySelector('.CoveoSearchButton');
          searchButton.addEventListener('click', (e) => e.stopPropagation()); 

          instance.addEventListener('keydown', function (e) {
            const suggestion = document.getElementById('coveo-magicbox-suggestions');
            if (suggestion) {
              var queryWord = instance.value;
              var coveoOmnibox = document.querySelector('.CoveoOmnibox');
              if (coveoOmnibox) {
                var coveoOmniboxInstance = Coveo.get(coveoOmnibox);
                if (coveoOmniboxInstance) {
                  if (queryWord.length < 2 || e.keyCode === 8) {
                    coveoOmniboxInstance.disabled = true;
                  }
                  else {
                    coveoOmniboxInstance.disabled = false;
                  }
                  let searchButton = coveoOmnibox.parentNode.querySelector('.CoveoSearchButton')
                  if (e.keyCode == 13 && !instance.closest('.listing-search-interface') && document.querySelectorAll('.coveo-tab-section').length < 1) {
                    if (searchButton) {
                      searchButton.click()
                    }
                  }
                  let viewAllButton = document.querySelector('.submit-search')
                  if (viewAllButton) {
                    viewAllButton.addEventListener('click', e => {
                      e.preventDefault()
                      searchButton.click()
                    })
                  }
                }
              }
            }
          });
    });
  }


  document.addEventListener('DOMContentLoaded', function () {
    let horizontalBar = document.querySelector('.coveo-facet-column.facet-row')
    if (horizontalBar) {
      let inputContainer = horizontalBar.querySelector('.CoveoSearchbox')
      if (inputContainer) {
        inputContainer.parentElement.classList.add('horizontal-section-bar')
        if (horizontalBar.dataset.searchLabel) {
          let preLabel = document.createElement('span')
          preLabel.innerText = horizontalBar.dataset.searchLabel;
          preLabel.classList.add('pre-label')
          inputContainer.parentElement.insertBefore(preLabel, inputContainer)
        }
      }
    }
  })

})();
