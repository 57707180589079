'use strict';

(function () {
    const awards = document.querySelectorAll('.awards');

    function hideEmpty(instance) {
        const lists = instance.querySelectorAll('ul');

        if (lists.length) {
            lists.forEach(list => {
                const listContent = list.querySelectorAll('li');

                if (listContent.length === 0) {
                    instance.remove();
                }
            });
        } else if (lists.length === 0) {
            instance.remove();
        }

    }

    if (awards && !window.sc.isExperienceEditor) {
        awards.forEach(instance => {
            hideEmpty(instance);
        });
    }
})();