'use strict';

(function () {
    const carousel = document.querySelectorAll('.carousel');

    if (carousel.length > 0 && !window.sc.isExperienceEditor) {


        const currentUrl = window.location.href;
        console.log(currentUrl);

        var paths = currentUrl.split('/');
        paths.shift();

        console.log(paths);

        var langCode = '';
        if (paths[2].length == 2) {
            var langCode = paths[2];
        }

        var prevText = 'Previous';
        var nextText = 'Next'
        if (langCode == 'fr') {
            prevText = 'Precedent';
            nextText = 'Suivant'
        }

        carousel.forEach(slider => {

            let timer = slider.getAttribute('data-timer');

            if (timer == "") {
                timer = 0;
            }

            let timerInt = parseInt(timer);

            if (timerInt < 0) {
                timerInt = 0;
            }

            let autoPlayBool = false;

            if (timerInt > 0) {
                autoPlayBool = true;
            }

            tns({
                container: slider,
                controlsPosition: 'bottom',
                navPosition: 'bottom',
                controlsText: [prevText, nextText],
                speed: 1500,
                autoplay: autoPlayBool,
                autoplayTimeout: timerInt,
                autoplayHoverPause: false,
                autoplayButtonOutput: false
            });
        });
    }
})();