'use strict';

(function() {
	const socialShare = document.querySelectorAll('.social-share');

	function closeAll(e) {
		const event = e || window.event;
		const element = event.srcElement ? event.srcElement : event.target;

		let clickInsideMenu = false;

		const socialShareToggler = document.querySelectorAll('.social-share *');
		socialShareToggler.forEach((i) => {
			if (element == i) clickInsideMenu = true;
			return;
		});

		if (clickInsideMenu) return;

		function isDropdown(container) {
			return element === container ? true : container.contains(element);
		}

		socialShare.forEach((instance) => {
			if (!isDropdown(instance)) {
				document
					.querySelectorAll('.social-share-toggler.social-share-close[aria-expanded=true]')
					.forEach((openedCloseButton) => openedCloseButton.click());
			}
		});
	}

	function toggle(container) {
		//console.log('toggled')
		const buttons = container.querySelectorAll('.social-share-toggler');
		const popup = container.querySelector('.social-share-list');

		if (buttons[0].getAttribute('aria-expanded') === 'true') {
			// close
			buttons.forEach((button) => {
				button.setAttribute('aria-expanded', 'false');
			});

			popup.classList.add('animating');

			setTimeout(function() {
				popup.classList.remove('open');
				if (document.querySelectorAll('.social-share-list.open').length < 1) {
					document.removeEventListener('click', closeAll);
					document.removeEventListener('touchstart', closeAll);
				}
			}, 200);

			setTimeout(function() {
				popup.classList.remove('animating');
			}, 600);
		} else {
			// open
			buttons.forEach((button) => {
				button.setAttribute('aria-expanded', 'true');
			});

			popup.classList.add('animating');

			setTimeout(function() {
				popup.classList.add('open');
			}, 200);

			setTimeout(function() {
				popup.classList.remove('animating');
			}, 600);

			document.addEventListener('click', closeAll);
			document.addEventListener('touchstart', closeAll);
		}
	}

	if (socialShare.length > 0) {
		socialShare.forEach((instance) => {
			const buttons = instance.querySelectorAll('.social-share-toggler');

			buttons.length > 0 &&
				buttons.forEach((button) => {
					button.addEventListener('click', function(e) {
						e.stopPropagation();
						toggle(instance);
					});
				});
		});
	}
	//  window.setTimeout(()=>{console.log(shareBox.getBoundingClientRect().bottom)}, 1200)

	jQuery(document).ready(function() {
		let tooLowIcon = document.querySelector('.hero--large .social-share-toggler');
		if (tooLowIcon) {
			let windowSize = window.innerHeight || document.documentElement.clientHeight;
			tooLowIcon.addEventListener('click', () => {
				if (tooLowIcon.getBoundingClientRect().bottom + 300 > windowSize) {
					let scrollAmount = tooLowIcon.getBoundingClientRect().bottom + 300 - windowSize;
					jQuery('html, body').animate({ scrollTop: scrollAmount + 200 }, 'slow');
				}
			});
		}
	});
})();
