'use strict';

(function () {

    var addQuotationsToResultSummary = function (totalResults) {
        if (totalResults > 0) {
            var querySummary = document.querySelector(".CoveoQuerySummary");
            if (querySummary) {
                var children = querySummary.children;
                if (children !== undefined && children !== null && children.length > 0) {
                    var grandChildren = children[0].children;
                    if (grandChildren !== undefined && grandChildren !== null && grandChildren.length > 0) {
                        var queryKeyword = grandChildren[grandChildren.length - 1].innerHTML;
                        var queryExists = parseInt(queryKeyword) !== totalResults;
                        if (queryExists && isNaN(queryKeyword.replace(",", ""))) {
                            if (queryKeyword.indexOf('"') !== 0
                                && queryKeyword.indexOf('"') !== queryKeyword.length - 1) {
                                grandChildren[grandChildren.length - 1].innerHTML = '"' + queryKeyword + '"';
                            }
                        }
                    }
                }
            }
        }
    };

    var hideSortOnNoResult = function () {
        var noResult = document.querySelector(".coveo-query-summary-no-results-string");
        var sortBy = document.querySelector(".sort-by-label");
        if (noResult !== undefined && noResult !== null) {
            if (sortBy !== undefined && sortBy !== null) {
                sortBy.style.display = "none";
            }
        }
        else {
            if (sortBy !== undefined && sortBy !== null) {
                sortBy.style.display = "block";
            }
        }
    };

    document.addEventListener("afterInitialization", function () {
        var searchInterface = document.querySelector(".search-page-interface");
        if (searchInterface === undefined || searchInterface === null) {
            searchInterface = document.querySelector(".listing-search-interface");
        }
        if (searchInterface) {

            var root = document.body;

            root.addEventListener('querySuccess', function (e) {
                var totalResults = 0;
                if (e !== undefined && e !== null
                    && e.detail !== undefined && e.detail !== null
                    && e.detail.results !== undefined && e.detail.results !== null
                    && e.detail.results.totalCount !== undefined && e.detail.results.totalCount !== null) {
                    totalResults = e.detail.results.totalCount;
                }
                addQuotationsToResultSummary(totalResults);
                hideSortOnNoResult();
            });
        }
    });
})();
