'use strict';

(function() {
	const lists = document.querySelectorAll('.expertise ul');

	function listSize(list) {
		const items = list.querySelectorAll('li');

		items.forEach((li) => {
			let link = li.querySelector('a');
			if (link) {
				link.innerText = link.innerText.trim();
			}
		});

		const size = items.length;
		let columns = 2;

		if (size >= 7) {
			columns = 3;
		}

		list.classList.add('rollup-cols--' + columns);
	}

	function expertiseWithAssistant() {
		let hasAssistant = document.querySelector('.expertise .assistants');
		if (hasAssistant) {
			document.querySelector('.expertise').classList.add('has-assistant');
		}
	}

	let linkRollups = document.querySelectorAll('.link-rollup');
	if (linkRollups.length > 0) {
		linkRollups.forEach((lr) =>
			lr.querySelectorAll('a').forEach((i) => {
				i.innerText = i.innerText.trim();
			})
		);
	}

	lists.length > 0 && lists.forEach((list) => {
			listSize(list);
		});

	expertiseWithAssistant();
})();
