'use strict';

window.getSCLabel = function (identifier) {
    var promise = new Promise(function (resolve, reject) {
        if (identifier !== undefined && identifier !== null && identifier !== "") {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function () {
                resolve(xhttp.response);
            };
            xhttp.open("GET", "/Blg/api/Label/GetLabel?identifier=" + identifier, true);
            xhttp.send();
        }
    });
    return promise;
};