'use strict';

(function() {
	const relatedInsights = document.querySelectorAll('.related-insights');

	if (relatedInsights.length > 0 && !window.sc.isExperienceEditor) {
		relatedInsights.forEach((instance) => {
			let allItems = instance.querySelectorAll('ul > li.related-insights-item');
			let hiddenItems = instance.querySelectorAll('ul > li.related-insights-item.d-none');

            
			const INITIAL_HIDDEN_COUNT = hiddenItems.length;
            
			const button = instance.querySelector('.read-more--toggle');
            
			if (button) {
                let showPerClick = button.getAttribute('data-show-per-click');
				button.setAttribute('data-hidden-items', INITIAL_HIDDEN_COUNT);

				button.addEventListener('click', () => {
					if (button.getAttribute('data-hidden-items') > 0) {
						//read more
						for (i = 0; i < showPerClick; i++) {
							if (hiddenItems[i]) {
								hiddenItems[i].classList.remove('d-none');
							}
						}
						hiddenItems = instance.querySelectorAll('ul > li.related-insights-item.d-none');
						button.setAttribute('data-hidden-items', hiddenItems.length);
						if (hiddenItems.length < 1) {
							button.setAttribute('aria-expanded', true);
							button.setAttribute('data-read', 'less');
						}
					} else {
						//read less

						button.setAttribute('aria-expanded', false);
						allItems.forEach((item, index) => {
							if (index >= allItems.length - INITIAL_HIDDEN_COUNT) {
								item.classList.add('d-none');
							}
						});
						hiddenItems = instance.querySelectorAll('ul > li.related-insights-item.d-none');
						button.setAttribute('data-hidden-items', hiddenItems.length);
					}
				});
			}
		});
        /*
        Old behavior
			let hiddenCounter = 0;
			let itemsCounter = 0;
			const button = instance.querySelector('.read-more--toggle');
			if (button)
				button.addEventListener('click', () => {
					let containers = instance.querySelectorAll('ul');

					if (button.getAttribute('aria-expanded') == 'false') {
						button.setAttribute('aria-expanded', true);
						button.setAttribute('data-read', 'less');

						containers.forEach((ul) => {
							ul.querySelectorAll('.related-insights-item').forEach((li) => {
								itemsCounter++;
								if (li.classList.contains('d-none')) hiddenCounter++;
								li.classList.remove('d-none');
							});
						});
					} else {
						button.setAttribute('aria-expanded', false);

						containers.forEach((ul) => {
							ul.querySelectorAll('.related-insights-item').forEach((li, index) => {
								if (index >= itemsCounter - hiddenCounter) li.classList.add('d-none');
							});
						});
						hiddenCounter = 0;
						itemsCounter = 0;
					}
				});
		});*/
	}
})();
