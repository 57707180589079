'use strict';

(function() {
	const insight = document.querySelectorAll('.event-details');

	if (insight.length > 0 && !window.sc.isExperienceEditor) {
		insight.forEach((instance) => {
			const button = instance.querySelector('.save-the-date');
			if (button)
				button.addEventListener('click', () => {
					var url = button.getAttribute('data-calendar');
					var urlContent = encodeURIComponent(url);
					let icsFile = new Blob([ url ], { type: 'text/calendar;charset=utf-8' });
					let filename = document.title + '.ics';

					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(icsFile, filename);
					} else if (/Mobi/.test(navigator.userAgent)) {
						window.open('data:text/calendar;charset=utf8,' + urlContent);
					} else {
						let hiddenElement = document.createElement('a');
						hiddenElement.href = URL.createObjectURL(icsFile);
						hiddenElement.target = '_blank';
						hiddenElement.download = filename;
						hiddenElement.click();
					}
				});
		});
	}
})();
