'use strict';

(function() {
	const upevslider = document.querySelectorAll('.upcoming-events-slider');

	let tallest = 0;

	let resizeCard = (slider) => {
		tallest = 0;
		let cards = slider.querySelectorAll('.default-card-list');
		if (cards.length > 0) {
			cards.forEach((card) => (tallest = card.offsetHeight > tallest ? card.offsetHeight : tallest));
			cards.forEach((card) => (card.style.height = tallest + 'px'));
		}
	};

	if (upevslider.length > 0 && !window.sc.isExperienceEditor) {
		upevslider.forEach((slider) => {
			tns({
				container: slider,
				controlsPosition: 'bottom',
				navPosition: 'bottom',
				controlsText: [ '', '' ],
				speed: 1500,
				items: 1,
				responsive: {
					650: {
						items: 2,
						slideBy: 2
					},
					1024: {
						items: 3,
						slideBy: 3
					}
				}
			});
			resizeCard(slider);
		});
	}
})();
