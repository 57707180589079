'use strict';

(function () {
    const insight = document.querySelectorAll('.insight');

    if (insight.length > 0 && !window.sc.isExperienceEditor) {
        insight.forEach(instance => {
            const button = instance.querySelector('.btn-insight-read-more--toggle');
            if (button) button.addEventListener('click', () => {
                var hiddenInsights = instance.querySelector('.hidden-insight');
                hiddenInsights.style.display = 'block';
            });
        })
    }

    if (window.location && window.location.pathname
        && window.location.pathname.toLowerCase().indexOf("/insights/") >= 0) {

        var services = "", topics = "";
        var layerData = document.getElementById("datalayer");
        if (layerData) {
            services = layerData.dataset.services;
            topics = layerData.dataset.topics;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "content": {
                    "topic": topics,
                    "service": services
                }
            });
        }
    }

})();