(function() {
	//Removes language bar duplicate
	let missingLanguage = document.querySelectorAll('.missing-language-alert');
	if (missingLanguage.length > 1) {
		missingLanguage.forEach((i, index) => {
			if (index != 1) {
				i.parentElement.removeChild(i);
			}
		});
	}
})();