'use strict';
(function () {
    jQuery(window).scroll(function () {
        var height = jQuery(window).scrollTop();
        if (height > 499) {
            jQuery('#back-to-top').fadeIn();
        } else {
            jQuery('#back-to-top').fadeOut();
        }
    });
    jQuery(document).ready(function () {
        jQuery("#back-to-top").click(function (event) {
            event.preventDefault();
            jQuery("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });

    });
})();