'use strict';

(function () {
  const readMoreContainer = document.querySelectorAll('.read-more-container');

  const hideExtraElements = instance => {
    const allElements = instance.children
    const elementsCount = instance.childElementCount - 1
    const readMoreButton = instance.querySelector('.read-more--content')
    const hiddenChildren = []

    for (var i = 0; i < elementsCount; i++) {
      if (i >= instance.displayedQuantity) {
        hiddenChildren.push(allElements[i])
      }
    }

    hiddenChildren.length > 0 && hiddenChildren.forEach(item => {
      instance.removeChild(item)
      readMoreButton && readMoreButton.appendChild(item)
    })
  }

  const hideReadMoreButton = instance => {
    let div = instance.querySelector('.read-more')
    if (div) {
      div.parentNode.removeChild(div)
    }
  }

  readMoreContainer.length > 0 && readMoreContainer.forEach(instance => {
    const displayedQuantity = instance.querySelector('.display-quantity');
    if (displayedQuantity) {
      instance.displayedQuantity = displayedQuantity.innerText
    }
    if (instance.childElementCount - 1 > instance.displayedQuantity) {
      hideExtraElements(instance)
    } else {
      hideReadMoreButton(instance)
    }

  });
})();