(function () {
    const imageTextCarousel = document.querySelectorAll('.image-text-carousel');

    if (imageTextCarousel.length > 0 && !window.sc.isExperienceEditor) {
        imageTextCarousel.forEach(slider => {
            let slideList = slider.querySelector(".image-text-carousel-wrapper");
            let interval = slideList.dataset.interval || 1000;
            interval *= 1000;
            let count = 1;
            let items = slideList.querySelectorAll("li").length;
            let prev = slider.querySelector(".prev");
            let next = slider.querySelector(".next");
            let navigation = slider.querySelectorAll(".nav button");

            const prevSlide = () => {
                if (count > 1) {
                    count -= 2;
                    slideList.style.left = "-" + count * 100 + "vw";
                    count++;
                }
                else if (count == 1) {
                    count = items - 1;
                    slideList.style.left = "-" + count * 100 + "vw";
                    count++;
                }
                updateNavigation()
            };

            const nextSlide = () => {
                if (count < items) {
                    slideList.style.left = "-" + count * 100 + "vw";
                    count++;
                }
                else if (count = items) {
                    slideList.style.left = "0px";
                    count = 1;
                }
                updateNavigation()
            };

            const goToSlide = (slideNumber) => {
                count = slideNumber;
                nextSlide()
            }

            const updateNavigation = () => {
                navigation.forEach(button => {
                    button.dataset.active = button.dataset.nav == (count - 1) ? "true" : "false";
                })
            }

            next.addEventListener("click", function () {
                nextSlide();
            });

            prev.addEventListener("click", function () {
                prevSlide();
            });

            navigation.forEach(button => {
                button.addEventListener('click', () => goToSlide(button.dataset.nav))
            })

            updateNavigation()

            if (interval >= 1000) {
                setInterval(function () {
                    nextSlide()
                }, interval);
            }


            //Mobile support
            let startPos = 0;

            const swipping = e => {
                let currentPosition = e.changedTouches["0"].clientX;

                if (e.type == "touchstart") {
                    startPos = currentPosition
                } else {
                    if ((startPos + 100) < currentPosition) {
                        prevSlide()
                    }
                    if ((startPos - 100) > currentPosition) {
                        nextSlide()
                    }
                }
            }
            slideList.querySelectorAll("li").forEach(li => {
                li.addEventListener("touchstart", swipping)
                li.addEventListener("touchend", swipping)
            })

        });
    }

    let heightEqualsTallestText = () => {
        let tallest = 0;
        document.querySelectorAll('.carousel-text').forEach(i => {
            tallest = (i.offsetHeight > tallest) ? i.offsetHeight : tallest
        })
        document.querySelector('.image-text-carousel').style.minHeight = tallest + 300 + "px"

    }

    if (document.querySelector('.hero-carousel')) {
        heightEqualsTallestText()
    }
})();
