'use strict';

const sitecore = (function () {

    function isExperienceEditor() {
        return document.querySelector('body').classList.contains('page-editor')
    }

    return {
        isExperienceEditor = isExperienceEditor()
    } 
 })();

window.sc = sitecore;
var Coveo;